<template>
	<div
		class="payment"
	>
		<v-tabs
			v-if="false"
			fixed-tabs
			color="success2"
		>
			<v-tab 
				class="font-weight-bold" style="border-bottom: 2px solid #ddd;"
				@click="list('payment')"
				>결제내역</v-tab>
			<v-tab 
				class="font-weight-bold" style="border-bottom: 2px solid #ddd;" 
				@click="list('cancel')"
			>취소내역</v-tab>
		</v-tabs>

		<div class="pa-3 bg-white">
	
			<div>
				<v-row
					class="pa-0 ma-0"
				>
					<v-col 
						class="text-caption pa-0 ma-0"
					>{{ search.inquiryDate | transDate }}</v-col>
					<v-col
						cols="7"
						class="pa-0 ma-0"
					>
						<v-divider style="margin-top: 10px;"></v-divider>
					</v-col>
				</v-row>
			</div>
				
			<v-card
				v-for="(item, index) in items"
				:key="index"
				
				elevation="0"
				class="mt-3 mb-6 card-top"
				:to="'/Payment/Item/' + item.tranSeq"
				
				tile
			>
				
				
				<v-card-text
					class="pa-0 mt-5 text-caption"
				>
					{{ item.paymentDateTime.split(' ')[1].replaceAll(':', '') | transTime }}
					<v-btn 
						class="float-right rounded-pill" 
						color="success2"
						outlined
						x-small
					>
						상세보기
					</v-btn>
				</v-card-text>
				<v-card-title 
					class="pa-0 mt-0 d-block text-subtitle-2">
					{{ item.productName }}
					<span class="float-right text-subtitle-1 font-weight-bold" color="success2">{{ item.paymentAmount | makeComma }} 원</span> 
				</v-card-title>
			</v-card>
			
			<v-card
				v-if="items.length <= 0"
				class="mt-3 mb-6 card-top"
				elevation="0"
			>
				<v-card-text
					class="text-center mt-10"
				>
					조회된 정보가 없습니다
				</v-card-text>
			</v-card>
			
		</div>
		
		<ScrollPagination
			@onScroll="nextData"
		></ScrollPagination>
		
	</div>
</template>
<script>

	import ScrollPagination from '@/components/ScrollPagination'
	
	export default{
		name: 'PaymentRegist'
		,components: { ScrollPagination }
		,created: function(){
		
			if(this.user.role != 'ROLE_MERCHANT'){
				this.$set(this.program, 'not_navigation', true)
			}
			this.$emit('setProgram', this.program, this.options)
			
			this.getSummary()
		}
		,props: ['callBack', 'user']
		,data: function(){
			return {
				program: {
					title: '결제 현황'
					,not_header: true
				}
				,search: {
					inquiryDate: this.$route.params.paymentDate
					,'pagination.page': 1
				}
				,totalPage: 0
				,transStatus: [
					{
						code: '00'
						,codeName: '승인'
					}
					,{
						code: '05'
						,codeName: '취소 요청'
					}
					,{
						code: '10'
						,codeName: '당일 취소'
					}
					,{
						code: '20'
						,codeName: '매입 취소'
					}
					,{
						code: '90'
						,codeName: '망상 취소'
					}
				]
				,list_date: this.$route.params.paymentDate
				,items:[
				]
				,summary: {
					salesManager: ''
				}
			}
		}
		,filters: {
			transDate: function(val){
				
				if(val){
					let weeks = ['일', '월', '화', '수', '목', '금', '토']
					let day = new Date(val.substring(0, 4) + '-' + val.substring(4, 6) + '-' + val.substring(6, 8)).getDay()
					console.log(day)
					let week = weeks[day]
					return val.substring(0, 4) + '년' + val.substring(4, 6) + '월' + val.substring(6, 8) + '일(' + week + ')' 
				}else{
					return ''
				}
			}
			,transTime: function(val){
				if(val){
					return val.substring(0, 2) + ':' + val.substring(2, 4) + ':' + val.substring(4, 6)
				}else{
					return ''
				}
			}
		}
		,computed: {
			checkDate: function(val){
				if(this.list_date == val){
					return false
				}else{
					return true
				}
			}
		}
		,methods: {
			getSummary: function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/sales/history/summary/' + this.$route.params.salesSeq
					,request_params: this.search
					,authorize: true
					,callBack: 'setSummary'
				})
			}
			,getData: function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/sales/history/' + this.$route.params.salesSeq
					,request_params: this.search
					,authorize: true
					,callBack: 'setItems'
				})
			}
			,nextData: function(){
				if(this.search['pagination.page'] >= this.totalPage){
					return false
				}else{
					this.search['pagination.page']++
					this.getData()
				}
			}
			,getSearch: function(){
//				this.searchForm()
				this.getData()
			}
			,more: function(){
				//
			}
			,setItems: function(call){
				console.log('payment list setitems')
				
				if(call.result){
					if(this.search['pagination.page'] > 1){
						this.items = this.items.concat(call.items.content.content)
					}else{
						this.items = call.items.content.content
					}
					
					this.totalPage = call.items.content.totalPages
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
			,searchData: function(call){
				console.log('searchData')
				console.log(call)
				
				let from
				let to
				
				if(call.params.dates.length > 1){
					from = call.params.dates[0].replace(/-/g, '')
					to = call.params.dates[1].replace(/-/g, '')
				}else{
					from = call.params.dates[0].replace(/-/g, '')
					to = from
				}
				
				if(from > to){
					this.search.searchFrom = to
					this.search.searchTo = from 
				}else{
					this.search.searchFrom = from
					this.search.searchTo = to
				}
				
				this.getData()
			}
			,searchForm: function(){
				this.$emit('modal', {
					title: '결제 현황 상세 조회'
					,callBack: {
						name: '조회'
						,color: 'success'
						,do: 'searchData'
					}
					,contents: '<v-date-picker v-model="picker" type="month"></v-date-picker>'
					,contents_type: 'calendar'
					,accept: '닫기'
					,icon: 'mdi-calendar'
					,icon_color: ''
				})
			}
			,list: function(type){
			
				this.items = []
				this.totalPage = 0
				this.search['pagination.page'] = 1
				if(type == 'payment'){
					this.search.transStatus = '00'
					this.search.massageType = '10'
				}else{
					this.search.transStatus = null
					this.search.massageType = '40'
				}
				
				this.list_date = null
			
				this.getData()
			}
			,setSummary: function(call){
			
				if(call.result){
					this.summary = call.items.content
					this.program.title = this.summary.salesManager
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
				
				this.getSearch()
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					if(call.name == 'setItems'){
						this.setItems(call)
					}else if(call.name == 'search'){
						this.searchForm()
					}else if(call.name == 'searchData'){
						this.searchData(call)
					}else if(call.name == 'setSummary'){
						this.setSummary(call)
					}
				}
			}
			,items: {
				deep: true
				,handler: function(call){
					for(var index in call){
						let item = call[index]
						let paymentDateTime = item.paymentDateTime.split(' ')[0].replaceAll('-', '')
						
						if(this.list_date == paymentDateTime){
							item.viewDt = false
						}else{
							this.list_date = paymentDateTime
							item.viewDt = true
						}
					}
				}
			}
		}
	}
</script>

<style>
	.payment {background: #eee; margin-bottom: 50px !important;}
	.payment .caption {border-top: 2px solid #555; border-bottom: 1px solid #ddd; }
	.payment th {border-right: 1px solid #ddd;}
</style>